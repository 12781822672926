@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --bg-color: #FFF;
  --primary-color: #F5F5F5;
  --text-color: black;
  --surface-color: #E6E6E6;
  --accent-color: #93C5fD;
  --scrollbar-color: #D2D2D2;
}

.gutter {
  scrollbar-gutter: stable;
}

html.dark {
  --primary-color: #181818;
  --bg-color: #121212;
  --text-color: white;
  --surface-color: #242424;
  --accent-color: #004C98;
  --scrollbar-color: #484848;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

@media (min-width: 550px) {
  body {
    background-image: url("img/svg/bg-tile.svg");
    background-repeat: repeat;
    background-size: 30px 24px;
    background-position: center;
  }
}

.skeleton {
  overflow: hidden;
  position: relative;
  background: linear-gradient(to right, rgba(143, 142, 141, 20%) 0%, rgba(237, 235, 233, 20%) 50%, rgba(143, 142, 141, 20%) 100%) 0px 0px / 100% 100% rgba(243, 242, 241, 20%);
  animation: shimmer 3s infinite linear;
  background-color: var(--text-color);
  opacity: 25%;
}

.shimmer {
  background: linear-gradient(to right, rgba(143, 142, 141, 40%) 0%, rgba(237, 235, 233, 40%) 50%, rgba(143, 142, 141, 40%) 100%) 0px 0px / 100% 100% rgba(243, 242, 241, 40%);
  animation: shimmer 3s infinite linear;
}

@keyframes shimmer {
  0% {
    background-position: 0px 0px;
  }
  100% {
    background-position: 296px 0px;
  }
}


/***************************
******** Scrollbar *********
***************************/

::-webkit-scrollbar {
  width: 0.5rem;
}

::-webkit-scrollbar-track {
  background: var(--scrollbar-color);
}

::-webkit-scrollbar-thumb {
  background: var(--surface-color);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color);
}

/* For Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--surface-color) var(--scrollbar-color);
}
